/* style.css */

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow: auto;
}

/* Login Container */
.login-container {
  display: flex;
}

/* Login Image */
.login-image {
  flex: 1;
  height: 100vh;
  display: block;
  background-size: cover;
  border-radius: 20px;
  background-position: left;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1); 
}

/* Login Content */
.login-content {
  width: 100%; /* Ensure the container fills the available width */
  padding: 60px;
  text-align: center;
  margin: auto;
  z-index: 1; /* Ensure the login content appears above the image */
  /* background-color: ghostwhite; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border-radius: 5%;
}

/* Header Section */
.header-section {
  margin-bottom: 20px;
}

/* Logo */
.logo {
  max-width: 500px;
  max-height: 100px;
  margin-bottom: 30px;
}

/* Logo */
.logo-sidebar {
  max-width: 80px;
  max-height: 45px;
  margin-bottom: 10px;
  margin-right: 10px;
  box-shadow: 0 10px 8px rgba(46, 48, 47, 0.1);
}

/* Form Section */
.login-form {
  /* margin-top: 80px; */
}

/* Additional Content */
.additional-content {
  margin-top: 20px;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: -5px;
}

/* Enabled Button Styles */
.custom-enabled-button {
  background-color: green;
  color: white;
}

/* Disabled Button Styles */
.custom-disabled-button {
  background-color: blue;
  color: yellow;
}

.signup-form-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-form-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('https://source.unsplash.com/random?dentist');
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  z-index: -1;
}

.default-form {
  max-width: 100%;
  width: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: -5px;
}

/* Button Styles */
.custom-back-button {
  background-color: white;
  color: black;
}

.custom-signup-button {
  background-color: darkgreen;
  color: white;
}

.signup-heading {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center; /* Center the text horizontally */
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
}

.appBar {
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.appBarShift {
  width: calc(100% - 240px);
}

.menuButton {
  margin-right: 36px;
}

.hide {
  display: none;
}

.drawerPaper {
  width: 240px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawerOpen {
  width: 240px;
}

.drawerClose {
  width: 60px;
}

.content {
  margin-left: 60px;
  padding: 20px;
}

.paper {
  padding: 20px;
}
